<div class="modal">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="modal-header">
        <div class="modal-title">
            <h3>OTP Verification</h3>
            <div class="div-spacer"></div>
            <button mat-icon-button class="btn-modal-close" color="primary" (click)="onClose(false)" tabIndex="-1">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="codeVerificationForm" (ngSubmit)="onSubmit()">
        <div class="modal-body">
            <div fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row" fxLayout.lg="row" fxLayout.xl="row"
                fxLayoutGap="16px" fxLayoutAlign="start stretch">
                <div fxFlex="100%">
                    <div class="message">
                        <h5>We have sent an OTP to <b>+{{countryCode}}{{mobile}}</b>. Please enter the OTP
                            below. <a (click)="onChangeMobile()">Change phone number</a></h5>
                    </div>
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline" color="accent">
                        <mat-label>4-Digit OTP*</mat-label>
                        <input matInput type="text" formControlName="code" minlength="4" maxlength="4"
                            (keypress)="onKeypress($event)" autocomplete="off" required>
                        <mat-error *ngIf="codeVerificationControls.code.hasError('required')">
                            OTP is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="codeVerificationControls.code.hasError('minlength')">
                            OTP must be <strong>4 digits</strong>
                        </mat-error>
                        <mat-error *ngIf="codeVerificationControls.code.hasError('maxlength')">
                            OTP must be <strong>maximum 4 digits</strong>
                        </mat-error>
                    </mat-form-field>
                    <div class="resendLink text-right">
                        <h4 *ngIf="timeLeft != 0" class="inactive-link">Resend OTP in <b>{{timeLeft}}s</b></h4>
                        <h4 *ngIf="timeLeft === 0" (click)="onResend()" class="active-link">Resend OTP now</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="full-square-flat-button" mat-flat-button type="submit"
                [disabled]="!codeVerificationForm.valid" color="accent">Proceed</button>
        </div>
    </form>
</div>