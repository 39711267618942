import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Email, ResetPassword, Signin, Signup } from './access.model';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

const BACKEND_URL_CUSTOMER = environment.apiBaseUrl + "/customers";
const BACKEND_URL_VERIFICATION = environment.apiBaseUrl + "/verification";
const BACKEND_URL_PARTNER = environment.apiBaseUrl + "/partners";
const BACKEND_URL_VERSION = environment.apiBaseUrl + "/version";
const BACKEND_URL_MAINTENANCE = environment.apiBaseUrl + "/maintenance";
const BACKEND_URL_SESSIONS = environment.apiBaseUrl + "/sessions";
const HEADERS = {
  headers: new HttpHeaders({
    'Authorization': 'Bearer ' + environment.webToken,
    'Username': environment.webUser
  })
}

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  // Set variables 
  private token: string;
  private sessionId: string;
  private userId: string;
  private isAuthenticated = false;
  private authStatusListner = new Subject<boolean>();
  private tokenTimer: any;
  private location: any;
  private user: any;
  private userUpdated = new Subject<{ user: any }>();
  private partner: any;
  private partnerUpdated = new Subject<{ partner: any }>();

  constructor(private http: HttpClient, private router: Router) { }

  // Get authenticated status
  getIsAuth() {
    return this.isAuthenticated;
  }

  // Get token
  getToken() {
    return this.token;
  }

  // Listen auth status
  getAuthStatusListner() {
    return this.authStatusListner.asObservable();
  }

  // Get session id
  getSessionId() {
    return this.sessionId;
  }

  // Get user id
  getUserId() {
    return this.userId;
  }

  // Get current location
  getLocation() {
    this.location = JSON.parse(localStorage.getItem('lytport_customer_location'));
    return this.location;
  }

  // Get app version
  getVersion() {
    return localStorage.getItem('lytport_app_version');
  }

  // Login to app with otp
  access(userData: any) {
    const authData: any = {
      countryCode: userData.countryCode,
      mobile: userData.mobile,
      hash: userData.hash,
      code: userData.code
    }
    this.http.post<{ message: string, token: string, expiresIn: number, expirationDate: string, userId: string, sessionId: string }>(BACKEND_URL_CUSTOMER + '/access/verification', authData, HEADERS)
      .subscribe((responseData) => {
        const token = responseData.token;
        this.token = token;
        this.sessionId = responseData.sessionId;
        // Check if token exist
        if (token) {
          // Set auth data and redirect to app
          const expireInDuration = responseData.expiresIn;
          this.setAuthTimer(expireInDuration);
          this.isAuthenticated = true;
          this.userId = responseData.userId;
          this.authStatusListner.next(true);

          const expirationDate = responseData.expirationDate;
          this.saveAuthData(this.sessionId, token, expirationDate, this.userId);
          this.getUser(this.userId);
        }
      }, (error: any) => {
        this.authStatusListner.next(false);
        console.log(error);
      });
  }

  // Login to app with password
  accessPassword(userData: any) {
    const authData: any = {
      countryCode: userData.countryCode,
      mobile: userData.mobile,
      password: userData.password
    }
    this.http.post<{ message: string, token: string, expiresIn: number, expirationDate: string, userId: string, sessionId: string }>(BACKEND_URL_CUSTOMER + '/password/access', authData, HEADERS)
      .subscribe((responseData) => {
        const token = responseData.token;
        this.token = token;
        this.sessionId = responseData.sessionId;
        // Check if token exist
        if (token) {
          // Set auth data and redirect to app
          const expireInDuration = responseData.expiresIn;
          this.setAuthTimer(expireInDuration);
          this.isAuthenticated = true;
          this.userId = responseData.userId;
          this.authStatusListner.next(true);

          const expirationDate = responseData.expirationDate;
          this.saveAuthData(this.sessionId, token, expirationDate, this.userId);
          this.getUser(this.userId);
        }
      }, (error: any) => {
        this.authStatusListner.next(false);
        console.log(error);
      });
  }

  // Register user
  registration(userData: any) {
    const authData: any = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      countryCode: userData.countryCode,
      mobile: userData.mobile,
      hash: userData.hash,
      code: userData.code
    }
    this.http.post<{ message: string, token: string, expiresIn: number, expirationDate: string, userId: string, sessionId: string }>(BACKEND_URL_CUSTOMER + '/registration/verification', authData, HEADERS)
      .subscribe((responseData) => {
        const token = responseData.token;
        this.token = token;
        // Check if token exist
        if (token) {
          // Set auth data and redirect to app
          const expireInDuration = responseData.expiresIn;
          this.setAuthTimer(expireInDuration);
          this.isAuthenticated = true;
          this.sessionId = responseData.sessionId;
          this.userId = responseData.userId;
          this.authStatusListner.next(true);

          const expirationDate = responseData.expirationDate;
          this.saveAuthData(this.sessionId, token, expirationDate, this.userId);
          this.getUser(this.userId);
        }
      }, (error: any) => {
        this.authStatusListner.next(false);
        console.log(error);
      });
  }

  // Register user with password
  registrationPassword(userData: any) {
    const authData: any = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      countryCode: userData.countryCode,
      mobile: userData.mobile,
      password: userData.password
    }
    this.http.post<{ message: string, token: string, expiresIn: number, expirationDate: string, userId: string, sessionId: string }>(BACKEND_URL_CUSTOMER + '/password/registration', authData, HEADERS)
      .subscribe((responseData) => {
        const token = responseData.token;
        this.token = token;
        // Check if token exist
        if (token) {
          // Set auth data and redirect to app
          const expireInDuration = responseData.expiresIn;
          this.setAuthTimer(expireInDuration);
          this.isAuthenticated = true;
          this.sessionId = responseData.sessionId;
          this.userId = responseData.userId;
          this.authStatusListner.next(true);

          const expirationDate = responseData.expirationDate;
          this.saveAuthData(this.sessionId, token, expirationDate, this.userId);
          this.getUser(this.userId);
        }
      }, (error: any) => {
        this.authStatusListner.next(false);
        console.log(error);
      });
  }

  // Set timer for token expiry, Logout after token expire
  private setAuthTimer(duration: number) {
    if (duration < 0) {
      this.logOut();
    }
  }

  // Set to local storage
  private saveAuthData(sessionId: string, token: string, expirationDate: string, userId: string) {
    localStorage.setItem("lytport_customer_session_id", sessionId);
    localStorage.setItem("lytport_customer_token", token);
    localStorage.setItem("lytport_customer_token_expiration", expirationDate);
    localStorage.setItem("lytport_customer_id", userId);
  }

  // Logout from app
  logOut() {
    if (this.userId && this.sessionId) {
      const sessionData = {
        id: this.sessionId,
        event: 'LOGOUT',
        entity: 'customer',
        entityId: this.userId
      }
      this.updateSession(sessionData);
    }
    this.isAuthenticated = false;
    this.authStatusListner.next(false);
    if (this.tokenTimer) {
      clearTimeout(this.tokenTimer);
    }
    this.clearAuthData();
    this.router.navigate(["/"]);
  }

  // Clear authentication data
  private clearAuthData() {
    if (this.userId && this.sessionId && this.token) {
      this.token = null;
      this.sessionId = null;
      this.userId = null;
      localStorage.removeItem("lytport_customer_session_id");
      localStorage.removeItem("lytport_customer_token");
      localStorage.removeItem("lytport_customer_token_expiration");
      localStorage.removeItem("lytport_customer_id");
    } else {
      localStorage.removeItem("lytport_customer_session_id");
      localStorage.removeItem("lytport_customer_token");
      localStorage.removeItem("lytport_customer_token_expiration");
      localStorage.removeItem("lytport_customer_id");
    }
  }

  // Check auto authentication on every route load
  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const expiresIn = Math.round(moment(authInformation.expirationDate).valueOf() / 1000) - Math.round(moment().valueOf() / 1000);
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.sessionId = authInformation.sessionId;
      this.userId = authInformation.userId;
      this.authStatusListner.next(true);
    }
    this.setAuthTimer(expiresIn);
  }

  // Get auth data
  private getAuthData() {
    const sessionId = localStorage.getItem("lytport_customer_session_id");
    const token = localStorage.getItem("lytport_customer_token");
    const expirationDate = localStorage.getItem("lytport_customer_token_expiration");
    const userId = localStorage.getItem("lytport_customer_id");
    if (!token || !sessionId || !expirationDate || !userId) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      sessionId: sessionId,
      userId: userId,
    }
  }

  // Check for local data
  localDataExists() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return false;
    }
    return true;
  }

  // Get user details
  getUser(id: string) {
    this.http.get<{ message: string, customer: any }>(BACKEND_URL_CUSTOMER + '/' + id)
      .pipe(map((userData) => {
        var firstChar = userData.customer.firstName.charAt(0);
        var secondChar = userData.customer.lastName.charAt(0);
        var shortName = firstChar + secondChar;
        return {
          user: {
            id: userData.customer._id,
            customerId: userData.customer.customerId,
            firstName: userData.customer.firstName,
            lastName: userData.customer.lastName,
            shortName: shortName.toUpperCase(),
            gender: userData.customer.gender,
            email: userData.customer.email,
            emailVerified: userData.customer.emailVerified,
            countryCode: userData.customer.countryCode,
            mobile: userData.customer.mobile,
            alternateMobile: userData.customer.alternateMobile,
            emergencyContacts: userData.customer.emergencyContacts,
            mobileVerified: userData.customer.mobileVerified,
            image: userData.customer.image,
            thumbnail: userData.customer.thumbnail,
            birthDate: userData.customer.birthDate,
            walletAmount: userData.customer.walletAmount,
            referralCode: userData.customer.referralCode,
            identity: userData.customer.identity,
            carbon: userData.customer.carbon,
            isActive: userData.customer.isActive
          }
        }
      })).subscribe((transformedUserData) => {
        this.user = transformedUserData.user;
        this.userUpdated.next({
          user: this.user
        });
      }, (error: any) => {
        console.log(error);
      });
  }

  // List user update
  getUserUpdatedListener() {
    return this.userUpdated.asObservable();
  }

  // Get partner details
  getPartner(id: string) {
    this.http.get<{ message: string, partner: any }>(BACKEND_URL_PARTNER + '/partner/' + id, HEADERS)
      .pipe(map((partnerData) => {
        return {
          partner: {
            id: partnerData.partner._id,
            address: partnerData.partner.address,
            companyName: partnerData.partner.companyName,
            companyFullName: partnerData.partner.companyFullName,
            companyAddress: partnerData.partner.companyAddress,
            companyPhoneNumber: partnerData.partner.companyPhoneNumber,
            companyEmail: partnerData.partner.companyEmail,
            currency: partnerData.partner.currency,
            isActive: partnerData.partner.isActive
          }
        }
      })).subscribe((transformedPartnerData) => {
        this.partner = transformedPartnerData.partner;
        this.partnerUpdated.next({
          partner: this.partner
        });
      }, (error: any) => {
        console.log(error);
      });
  }

  // List partner update
  getPartnerUpdatedListener() {
    return this.partnerUpdated.asObservable();
  }

  // Forgot password
  forgotPassword(email: Email) {
    return this.http.post<{ message: string, email: string, hash: string }>(BACKEND_URL_CUSTOMER + '/forgot-password', email, HEADERS);
  }

  // Reset password
  resetPassword(resetData: ResetPassword) {
    return this.http.post<{ message: string }>(BACKEND_URL_CUSTOMER + '/reset-password', resetData, HEADERS);
  }

  // Send otp on mobile
  sendAccessOTP(verificationData: any) {
    return this.http.post<{ message: string, countryCode: string, mobile: string, hash: string, code: number }>(BACKEND_URL_CUSTOMER + '/access', verificationData, HEADERS);
  }

  // Send otp on mobile
  sendRegistrationOTP(registrationData: any) {
    return this.http.post<{ message: string, countryCode: string, mobile: string, hash: string }>(BACKEND_URL_CUSTOMER + '/registration', registrationData, HEADERS);
  }

  // Send otp on mobile
  sendOTP(verificationData: any) {
    return this.http.post<{ message: string, countryCode: string, mobile: string, hash: string }>(BACKEND_URL_VERIFICATION + '/code', verificationData, HEADERS);
  }

  // Verify email user
  verifyEmailUser(verificationData: any) {
    return this.http.post<{ message: string, status: number }>(BACKEND_URL_VERIFICATION + '/link/verify', verificationData, HEADERS);
  }

  // Get app version
  getAppVersion(version: string) {
    const queryParams = `?version=${version}`;
    return this.http.get<{ message: string, matched: boolean, version: string }>(BACKEND_URL_VERSION + queryParams);
  }

  // Get app maintenance
  checkAppMaintenance() {
    return this.http.get<{ message: string, maintenance: boolean }>(BACKEND_URL_MAINTENANCE);
  }

  // Update session
  updateSession(sessionData: any) {
    this.http.put<{ message: string }>(BACKEND_URL_SESSIONS + '/' + sessionData.id, sessionData)
      .subscribe((resultData) => { }, (error: any) => {
        console.log(error);
      });
  }
}
