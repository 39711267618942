<mat-sidenav-container>
    <mat-sidenav #drawer [ngClass]="{hidden: !(isHandset | async)!.matches}" fixedInViewport="false"
        [attr.role]="isHandset  ? 'dialog' : 'navigation'" [mode]="(isHandset | async)!.matches ? 'over' : 'side'"
        [opened]="!(isHandset | async)!.matches">
        <mat-toolbar>
            <mat-toolbar-row>
                <div class="logo">
                    <a [routerLink]="['/']" aria-label="Redirect to home page">
                        <img class="text" src="assets\images\logo-full.svg" alt="Lytport Logo">
                    </a>
                </div>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="drower-menus">
            <div *ngIf="userIsAuthenticated" class="profile-section">
                <div class="profile-photo">
                    <div *ngIf="user?.image || user?.thumbnail" class="profile-photo-image">
                        <img *ngIf="!user?.thumbnail" [src]="user?.image" alt="User image" />
                        <img *ngIf="user?.thumbnail" [src]="user?.thumbnail" alt="User image thumbnail" />
                    </div>
                    <div *ngIf="!user?.image && !user?.thumbnail" class="profile-photo-icon">
                        <mat-icon>account_circle</mat-icon>
                    </div>
                </div>
                <div class="profile-info">
                    <h3>Hi, {{user?.firstName || '--'}} {{user?.lastName || '--'}}</h3>
                </div>
            </div>
            <div class="access-section" *ngIf="!userIsAuthenticated">
                <button mat-flat-button color="primary" (click)="onSignin()" aria-label="Login or Sign up">
                    <mat-icon>login</mat-icon> Login or Signup
                </button>
            </div>
            <div class="button-list-menus">
                <button class="action-menu" mat-menu-item [routerLink]="['/']" (click)="drawer.close()"
                    aria-label="Redirect to home page">
                    <div class="menu">
                        <mat-icon>home</mat-icon><span>Home</span>
                    </div>
                </button>
                <button class="action-menu" mat-menu-item [routerLink]="['/faq']" (click)="drawer.close()"
                    aria-label="Redirect to faq page">
                    <div class="menu">
                        <mat-icon>question_answer</mat-icon><span>FAQ's</span>
                    </div>
                </button>
                <button class="action-menu" mat-menu-item [routerLink]="['/contact']" (click)="drawer.close()"
                    aria-label="Redirect to support page">
                    <div class="menu">
                        <mat-icon>support_agent</mat-icon><span>Support</span>
                    </div>
                </button>
                <button class="action-menu" mat-menu-item [routerLink]="['/blog']" (click)="drawer.close()"
                    aria-label="Redirect to blog page">
                    <div class="menu">
                        <mat-icon>rss_feed</mat-icon><span>Blog</span>
                    </div>
                </button>
                <button *ngIf="userIsAuthenticated" class="action-menu" mat-menu-item
                    [routerLink]="['/account/profile']" (click)="drawer.close()"
                    aria-label="Redirect to my profile page">
                    <div class="menu">
                        <mat-icon>person</mat-icon><span>My Profile</span>
                    </div>
                </button>
                <button *ngIf="userIsAuthenticated" class="action-menu" mat-menu-item
                    [routerLink]="['/account/bookings']" (click)="drawer.close()"
                    aria-label="Redirect to my rides page">
                    <div class="menu">
                        <mat-icon>two_wheeler</mat-icon><span>My Rides</span>
                    </div>
                </button>
                <button *ngIf="userIsAuthenticated" class="action-menu" mat-menu-item (click)="onLogout()"
                    aria-label="Logout from app">
                    <div class="menu">
                        <mat-icon>logout</mat-icon><span>Logout</span>
                    </div>
                </button>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="topper" ngClass.xs="mobile" ngClass.sm="mobile" ngClass.md="tablet" ngClass.lg="desktop"
            ngClass.xl="desktop">
            <div fxHide.xs fxHide.sm class="quick-info">
                <!-- <a href="https://wa.aisensy.com/+918093080040" aria-label="Whatsapp number">
                    <img src="assets/images/social/whatsapp-white.svg" alt="Whatsapp" class="img-icon"> <span>+91
                        8093080040</span>
                </a> -->
                <a href="tel:8093080040" aria-label="Contact number">
                    <mat-icon>call</mat-icon> <span>+91 8093080040 | 8093080041</span>
                </a>
                <a href="mailto:support@lytport.com" aria-label="Contact email">
                    <mat-icon>mail</mat-icon> <span>support@lytport.com</span>
                </a>
            </div>
            <div fxHide.md fxHide.lg fxHide.xl class="quick-info">
                <!-- <a href="https://wa.aisensy.com/+918093080040" aria-label="Whatsapp number">
                    <img src="assets/images/social/whatsapp-white.svg" alt="Whatsapp" class="img-icon">
                </a> -->
                <a href="tel:8093080040" aria-label="Contact number">
                    <mat-icon>call</mat-icon>
                </a>
                <a href="mailto:support@lytport.com" aria-label="Contact email">
                    <mat-icon>mail</mat-icon>
                </a>
            </div>
        </div>
        <div class="toolbar" id="header" ngClass.xs="mobile" ngClass.sm="mobile" ngClass.md="tablet"
            ngClass.lg="desktop" ngClass.xl="desktop">
            <mat-toolbar>
                <mat-toolbar-row>
                    <mat-icon class="drawer-icon" (click)="drawer.toggle()" *ngIf="(isHandset | async)!.matches">menu
                    </mat-icon>
                    <div class="logo">
                        <a [routerLink]="['/']" aria-label="Redirect to home page">
                            <img class="text" src="assets/images/logo-full.svg" alt="Lytport Logo" />
                        </a>
                    </div>
                    <span class="menu-spacer"></span>
                    <div fxHide.lg fxHide.xl fxHide.md>
                        <a class="city-selector" (click)="onCitySelect()">
                            <mat-icon class="material-icons-outlined">pin_drop</mat-icon>
                            <span>{{currentLocation?.name || 'City'}}</span>
                            <mat-icon class="arrow">arrow_drop_down</mat-icon>
                        </a>
                    </div>
                    <div class="menus" fxHide.xs fxHide.sm>
                        <ul>
                            <li>
                                <a class="city-selector" (click)="onCitySelect()">
                                    <mat-icon class="material-icons-outlined">pin_drop</mat-icon>
                                    <span>{{currentLocation?.name || 'City'}}</span>
                                    <mat-icon class="arrow">arrow_drop_down</mat-icon>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/']" [class.active]="isLinkActive('/.')">
                                    <span>Home</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/faq']" routerLinkActive="active">
                                    <span>FAQ's</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/contact']" routerLinkActive="active">
                                    <span>Support</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/blog']" routerLinkActive="active">
                                    <span>Blog</span>
                                </a>
                            </li>
                            <li *ngIf="userIsAuthenticated">
                                <a [routerLink]="['/account/bookings']" routerLinkActive="active">
                                    <span>My Rides</span>
                                </a>
                            </li>
                            <li *ngIf="!userIsAuthenticated">
                                <button mat-flat-button color="accent" (click)="onSignin()"
                                    aria-label="Login or Sign up">
                                    <mat-icon>login</mat-icon> Login or Signup
                                </button>
                            </li>
                            <li *ngIf="userIsAuthenticated">
                                <div [matMenuTriggerFor]="profile" class="profile-image">
                                    <mat-icon *ngIf="!user?.image && !user?.thumbnail"
                                        class="profile-icon material-icons-outlined">
                                        account_circle</mat-icon>
                                    <span *ngIf="user?.image || user?.thumbnail">
                                        <img [src]="user?.image" *ngIf="!user?.thumbnail" class="profile-image"
                                            alt="User image">
                                        <img [src]="user?.thumbnail" *ngIf="user?.thumbnail" class="profile-image"
                                            alt="User image thumbnail">
                                    </span>
                                    <h3>Hi, {{user?.firstName}}</h3>
                                </div>
                                <mat-menu #profile="matMenu" xPosition="before" class="dropdown-menu">
                                    <div class="profile-box">
                                        <div class="profile">
                                            <h3>{{user?.firstName}} {{user?.lastName}}</h3>
                                        </div>
                                        <div class="menu-items">
                                            <button mat-menu-item [routerLink]="['/account/profile']"
                                                aria-label="Redirect to my profile page">
                                                <mat-icon class="material-icons-outlined">manage_accounts</mat-icon>
                                                <span>My Profile</span>
                                            </button>
                                            <button mat-menu-item (click)="onLogout()" aria-label="Logout from app">
                                                <mat-icon class="material-icons-outlined">exit_to_app</mat-icon>
                                                <span>Logout</span>
                                            </button>
                                        </div>
                                    </div>
                                </mat-menu>
                            </li>
                        </ul>
                    </div>
                </mat-toolbar-row>
            </mat-toolbar>
        </div>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>