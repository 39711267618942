import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-app-updates',
  templateUrl: './app-updates.component.html',
  styleUrls: ['./app-updates.component.scss']
})
export class AppUpdatesComponent implements OnInit {

  // Set veriable
  appType: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private readonly swUpdateService: SwUpdate) { }

  ngOnInit(): void {
    if (this.data) {
      this.appType = this.data.type;
    }
  }

  // Update app
  onUpdate() {
    if (this.appType === "APP") {
      this.swUpdateService.activateUpdate().then(() => window.location.reload());
    } else {
      window.location.reload();
    }
  }

}
