import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SnackbarService } from '../notifications/snackbar.service';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

    constructor(public snackbarService: SnackbarService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // const timeoutValue = 50000;
        return next.handle(req).pipe(
            // timeout(timeoutValue),
            catchError((error: any) => {
                // // Handle timeout error
                // if (error.name === 'TimeoutError') {
                //     this.snackbarService.error('Request timed out!', 'Dismiss', 'bottom', 4000);
                // }

                // Handle HttpErrorResponse errors
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 400) {
                        // Handle Invalid error
                        this.snackbarService.error(error.error.message, 'Dismiss', 'bottom', 4000);
                    }
                    if (error.status === 401) {
                        // Handle Unauthorized error
                        this.snackbarService.error(error.error.message, 'Dismiss', 'bottom', 4000);
                    }
                    if (error.status === 403) {
                        // Handle Forbidon error
                        this.snackbarService.error(error.error.message, 'Dismiss', 'bottom', 4000);
                    }
                    if (error.status === 404) {
                        // Handle Not Found error
                        this.snackbarService.error(error.error.message, 'Dismiss', 'bottom', 4000);
                    }
                    if (error.status >= 500) {
                        // Handle Server error
                        this.snackbarService.error('Internal server error!', 'Dismiss', 'bottom', 4000);
                    }
                    if (error.status === 0) {
                        // Handle Server not responding error
                        this.snackbarService.error('Somthing went wrong!', 'Dismiss', 'bottom', 4000);
                    }
                }
                return throwError(error);
            })
        );
    }
}