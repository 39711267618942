import { ApplicationRef, Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AppUpdatesComponent } from './app-updates/app-updates.component';
import { AccessService } from './public/access/access.service';
import { SnackbarService } from './shared/notifications/snackbar.service';

// Declare GTM dataLayer array
declare global {
  interface Window { dataLayer: any[]; }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private accessService: AccessService, public snackbarService: SnackbarService, private router: Router, public dialog: MatDialog, private activatedRoute: ActivatedRoute, private titleService: Title, private metaService: Meta, private readonly swUpdateService: SwUpdate, private appRef: ApplicationRef) {
    this.checkAppMaintenance();
    this.checkAppVersion(this.accessService.getVersion());
    this.checkForUpdates();
    this.checkAppUpdates();
  }

  ngOnInit(): void {
    // Set values for page
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((data) => {
      if (data && data.title) {
        // Set the page title
        this.titleService.setTitle(data.title);

        // Set the meta tags
        this.metaService.updateTag({ name: 'description', content: data.description });
        this.metaService.updateTag({ name: 'keywords', content: data.keywords });
        this.metaService.updateTag({ property: 'og:url', content: data.siteUrl });
        this.metaService.updateTag({ property: 'og:title', content: data.ogTitle });
        this.metaService.updateTag({ property: 'og:description', content: data.ogDescription });
        this.metaService.updateTag({ name: 'twitter:site', content: data.siteUrl });
        this.metaService.updateTag({ name: 'twitter:title', content: data.twitterTitle });
        this.metaService.updateTag({ name: 'twitter:description', content: data.twitterDescription });
      }
    });

    // Check user authentication on every route
    this.accessService.autoAuthUser();

    // Push GTM tags
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        // Set data layer for page route
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'page',
          pageName: item.url
        });
      }
    });
  }

  // Get child title
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  // Check for maintenance
  checkAppMaintenance() {
    this.accessService.checkAppMaintenance().subscribe(resultData => {
      if (resultData.maintenance) {
        this.router.navigate(["/maintenance"]);
      }
    }, (error: any) => {
      console.log(error);
    });
  }

  // Run for app been updated
  checkForUpdates() {
    if (!this.swUpdateService.isEnabled) {
      console.log("Service not enabled");
      return;
    }
    this.swUpdateService.available.subscribe(event => this.promptUpdate("APP"));
    this.swUpdateService.activated.subscribe(event => console.log("New app activated"));
  }

  // Check app updates
  checkAppUpdates() {
    this.appRef.isStable.subscribe((isStable) => {
      if (isStable) {
        const timeInterval = interval(6 * 60 * 60 * 1000);
        timeInterval.subscribe(() => {
          this.swUpdateService.checkForUpdate().then(() => console.log('checking for updates'));
        })
      }
    });
  }

  // Check app version
  checkAppVersion(version: string) {
    this.accessService.getAppVersion(version).subscribe(resultData => {
      if (!resultData.matched) {
        localStorage.setItem("lytport_app_version", resultData.version);
        // this.promptUpdate("SERVER");
      }
    }, (error: any) => {
      console.log(error);
    });
  }

  // Show update popup
  private promptUpdate(type: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "300px";
    dialogConfig.position = { top: '5%' };
    dialogConfig.data = { type: type };
    this.dialog.open(AppUpdatesComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) { }
      });
  }

}
