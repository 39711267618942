import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Password } from 'src/app/public/access/access.model';
import { SnackbarService } from 'src/app/shared/notifications/snackbar.service';
import { PasswordValidator } from 'src/app/shared/validators/password.validator';
import { AccountService } from '../../account.service';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements OnInit {

  // Set variables
  isLoading = false;
  hide = true;

  constructor(@Inject(MAT_DIALOG_DATA) private data, private formBuilder: FormBuilder, public accountService: AccountService, public snackbarService: SnackbarService, private dialogRef: MatDialogRef<ChangePasswordFormComponent>) { }

  // Form details
  passwordDetails: Password = {
    id: null,
    oldPassword: '',
    password: '',
    confirmPassword: ''
  }

  // Form builder
  passwordForm = this.formBuilder.group({
    id: null,
    oldPassword: [null, Validators.required],
    password: [null, Validators.required],
    confirmPassword: [null, Validators.required]
  }, { validator: PasswordValidator.matchPassword });

  ngOnInit(): void {
    // Patch values
    if (this.data) {
      this.patchValues(this.data.data);
    }
  }

  // Form getter
  get passwordControls() {
    return this.passwordForm.controls;
  }

  // Patch values
  patchValues(values) {
    this.passwordForm.patchValue({
      id: values.id
    });
  }

  // On form submit
  onSubmit() {
    if (this.passwordForm.valid) {
      this.isLoading = true;
      this.passwordDetails = {
        id: this.passwordControls.id.value,
        oldPassword: this.passwordControls.oldPassword.value,
        password: this.passwordControls.password.value
      }
      this.accountService.updatePassword(this.passwordDetails).subscribe(resultData => {
        this.isLoading = false;
        this.onClose(true);
        this.snackbarService.success('Password updated', 'Dismiss', 'bottom', 2000);
      }, (error: any) => {
        this.isLoading = false;
        console.log(error);
      })
    }
  }

  // Close modal
  onClose(status: boolean) {
    this.dialogRef.close(status);
  }

}

