<div class="modal">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="modal-header">
        <div class="modal-title">
            <div class="logo">
                <a [routerLink]="['/']" aria-label="Redirect to home page">
                    <img class="text" src="assets\images\logo-text.svg" alt="Lytport Logo">
                </a>
            </div>
            <div class="div-spacer"></div>
            <button *ngIf="show" mat-icon-button class="btn-modal-close" color="primary" (click)="onClose(false)"
                tabIndex="-1">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
    <div class="modal-body modal-body-scroller">
        <div fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row" fxLayout.lg="row" fxLayout.xl="row"
            fxLayoutGap="6px" fxLayoutGap.xs="0px" fxLayoutGap.sm="0px" fxLayoutAlign="start stretch">
            <div fxFlex="100%">
                <div class="cities-list">
                    <ng-container *ngFor="let city of cities">
                        <div class="city-item" *ngIf="city.isActive"
                            [ngClass]="{'selected-city': city.id === currentLocation?.id}" (click)="selectCity(city)">
                            <div class="item-image">
                                <img *ngIf="city.icon" src="{{city.icon}}" alt="City icon">
                            </div>
                            <div class="item-name">
                                <h3>{{city.name}}</h3>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer text-center">
        <h4>Select city to continue</h4>
    </div>
</div>