<div class="modal">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="modal-header">
        <div class="modal-title">
            <h3>Forgot Password</h3>
            <div class="div-spacer"></div>
            <button mat-icon-button class="btn-modal-close" color="primary" (click)="onClose(false)" tabIndex="-1">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="forgotpasswordForm" (ngSubmit)="onSubmit()">
        <div class="modal-body modal-body-scroller">
            <div fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row" fxLayout.lg="row" fxLayout.xl="row"
                fxLayoutGap="16px" fxLayoutGap.xs="0px" fxLayoutGap.sm="0px" fxLayoutAlign="start stretch">
                <div fxFlex="100%">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="fill" color="accent">
                        <mat-label>Email address*</mat-label>
                        <input matInput type="text" formControlName="email" autocomplete="off" required>
                        <mat-error *ngIf="forgotpasswordControls.email.hasError('required')">
                            Email address is <strong>required</strong>
                        </mat-error>
                        <mat-error
                            *ngIf="forgotpasswordControls.email.hasError('email') && !forgotpasswordControls.email.hasError('required')">
                            Please enter a valid email address
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button mat-flat-button class="full-square-flat-button" type="submit" [disabled]="!forgotpasswordForm.valid"
                color="accent">Continue</button>
        </div>
    </form>
</div>