import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Email } from 'src/app/public/access/access.model';
import { AccessService } from 'src/app/public/access/access.service';
import { SnackbarService } from 'src/app/shared/notifications/snackbar.service';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  // Set variables
  isLoading = false;

  constructor(private formBuilder: FormBuilder, public accessService: AccessService, public snackbarService: SnackbarService, private dialogRef: MatDialogRef<ForgotPasswordComponent>, public dialog: MatDialog) { }

  // Form details
  forgotpasswordDetails: Email = {
    email: ''
  }

  // Form builder
  forgotpasswordForm = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
  });

  ngOnInit(): void {
  }

  // Form getter
  get forgotpasswordControls() {
    return this.forgotpasswordForm.controls;
  }

  // On form submit
  onSubmit() {
    // Check form validation
    if (this.forgotpasswordForm.valid) {
      // Forgot password
      this.isLoading = true;
      this.forgotpasswordDetails = {
        email: this.forgotpasswordControls.email.value
      }
      this.accessService.forgotPassword(this.forgotpasswordDetails).subscribe(resultData => {
        this.isLoading = false;
        let codeDetails = {
          email: resultData.email,
          hash: resultData.hash
        }
        this.onResetPassword(codeDetails);
        this.snackbarService.success('Reset password verification code sent on your email address', 'Dismiss', 'bottom', 4000);
      }, (error: any) => {
        this.isLoading = false;
        console.log(error);
      });
    }
  }

  // On reset password
  onResetPassword(codeDetails: any) {
    this.onClose(true);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "420px";
    dialogConfig.maxHeight = "90vh";
    dialogConfig.position = { top: '5%' };
    dialogConfig.data = {
      code: codeDetails
    };
    this.dialog.open(ResetPasswordComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) {
        }
      });
  }

  // Number validation 
  onKeypress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // Close modal
  onClose(status: boolean) {
    this.dialogRef.close(status);
  }
}
