import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccessService } from 'src/app/public/access/access.service';
import { SnackbarService } from 'src/app/shared/notifications/snackbar.service';
import { PasswordValidator } from 'src/app/shared/validators/password.validator';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  // Set variables
  isLoading = false;
  email: string;
  private hash: string;
  hide = true;

  constructor(@Inject(MAT_DIALOG_DATA) private data, public accessService: AccessService, private formBuilder: FormBuilder, public snackbarService: SnackbarService, private dialogRef: MatDialogRef<ResetPasswordComponent>, public dialog: MatDialog) { }

  // Form builder
  resetpasswordForm = this.formBuilder.group({
    password: [null, Validators.required],
    confirmPassword: [null, Validators.required],
    code: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
  }, {
    validator: PasswordValidator.matchPassword
  });

  ngOnInit(): void {
    // Patch values
    if (this.data) {
      this.email = this.data.code.email;
      this.hash = this.data.code.hash;
    }
  }

  // Form getter
  get resetpasswordControls() {
    return this.resetpasswordForm.controls;
  }

  // On form submit
  onSubmit() {
    if (this.resetpasswordForm.valid) {
      this.isLoading = true;
      let codeVerificationDetails = {
        email: this.email,
        hash: this.hash,
        code: this.resetpasswordControls.code.value,
        password: this.resetpasswordControls.password.value
      }
      this.accessService.resetPassword(codeVerificationDetails).subscribe(resultData => {
        this.isLoading = false;
        this.onClose(true);
        this.snackbarService.success('Password has been changed! Now you can login with your new password.', 'Dismiss', 'bottom', 3000);
      }, (error: any) => {
        this.isLoading = false;
        console.log(error);
      });
    }
  }

  // On resend code
  onResend() {
    if (this.email) {
      this.isLoading = true;
      let verificationDetails = {
        email: this.email
      }
      this.accessService.forgotPassword(verificationDetails).subscribe(resultData => {
        this.isLoading = false;
        this.email = resultData.email;
        this.hash = resultData.hash;
        this.snackbarService.success('Verification code sent', 'Dismiss', 'bottom', 3000);
      }, (error: any) => {
        this.isLoading = false;
        console.log(error);
      });
    }
  }

  // On change email
  onChangeEmail() {
    this.onClose(true);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "420px";
    dialogConfig.maxHeight = "90vh";
    dialogConfig.position = { top: '5%' };
    this.dialog.open(ForgotPasswordComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) {
        }
      });
  }

  // Number validation 
  onKeypress(event: any) {
    const pattern = /[0-9\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // Close modal
  onClose(status: boolean) {
    this.dialogRef.close(status);
  }

}
