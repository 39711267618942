<div class="modal">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="modal-header">
        <div class="modal-title">
            <h3>Change Password</h3>
            <div class="div-spacer"></div>
            <button mat-icon-button class="btn-modal-close" color="primary" (click)="onClose(false)" tabIndex="-1">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
        <div class="modal-body">
            <input type="hidden" formControlName="id">

            <div fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row" fxLayout.lg="row" fxLayout.xl="row"
                fxLayoutGap="6px" fxLayoutAlign="start stretch">
                <div fxFlex="100%">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline" color="accent">
                        <mat-label>Old password*</mat-label>
                        <input matInput type="text" formControlName="oldPassword" autocomplete="off" required>
                        <mat-error *ngIf="passwordControls.oldPassword.hasError('required')">
                            Old password is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline" color="accent">
                        <mat-label>New password*</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password"
                            autocomplete="off" required>
                        <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="passwordControls.password.hasError('required')">
                            Password is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline" color="accent">
                        <mat-label>Confirm password*</mat-label>
                        <input matInput type="password" formControlName="confirmPassword" autocomplete="off" required>
                        <mat-error *ngIf="passwordControls.confirmPassword.hasError('required')">
                            Confirm password is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="passwordControls.confirmPassword.hasError('missMatch')">
                            Password not <strong>match!</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="instructions">
                <ul>
                    <li>Choose strong password for account, which helps to prevent misuse of your account.</li>
                </ul>
            </div>

        </div>
        <div class="modal-footer">
            <button mat-flat-button class="square-flat-button" type="submit" [disabled]="!passwordForm.valid"
                color="accent">Update</button>
        </div>
    </form>
</div>