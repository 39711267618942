import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ChangePasswordFormComponent } from 'src/app/private/account/profile/change-password-form/change-password-form.component';
import { AccessService } from 'src/app/public/access/access.service';
import { SearchService } from 'src/app/public/search/search.service';
import { SnackbarService } from 'src/app/shared/notifications/snackbar.service';
import { SignInComponent } from './sign-in/sign-in.component';
import { CitiesComponent } from './cities/cities.component';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  // Set variables 
  isLoading = false;
  userIsAuthenticated = false;
  private authListnerSub: Subscription;
  private userSubs: Subscription;
  userId: string;
  user: any;
  currentLocation: any;
  private timer: any;

  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);
  @ViewChild('drawer') drawerRef: MatSidenav;

  constructor(private accessService: AccessService, public searchService: SearchService, private breakpointObserver: BreakpointObserver, private router: Router, public snackbarService: SnackbarService, public dialog: MatDialog) { }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    let headerElement = document.getElementById('header');
    if (headerElement) {
      if (window.pageYOffset > 0) {
        headerElement.classList.add('white-toolbar');
      } else {
        headerElement.classList.remove('white-toolbar');
      }
    }
  }

  ngOnInit(): void {
    // Check for valid user 
    this.userIsAuthenticated = this.accessService.getIsAuth();
    this.authListnerSub = this.accessService.getAuthStatusListner().
      subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        // Check session
        if (this.userIsAuthenticated) {
          this.checkSession();
        }
      });

    // Check session
    if (this.userIsAuthenticated) {
      this.checkSession();
    }

    // Get user details
    this.getUserDetails();
    this.userSubs = this.accessService.getUserUpdatedListener().subscribe((userData: { user: any }) => {
      this.user = userData.user;

      if (this.user && !this.user.isActive) {
        // Check account status
        this.checkAccountStatus();
      }
    });

    // Get current location
    this.currentLocation = this.accessService.getLocation();
    if (!this.currentLocation) {
      this.onCitySelect();
    }
  }

  // Get user details
  getUserDetails() {
    this.userId = this.accessService.getUserId();
    if (this.userId) {
      this.accessService.getUser(this.userId);
    }
  }

  // On sign in
  onSignin() {
    this.drawerRef.close();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "420px";
    dialogConfig.maxHeight = "90vh";
    dialogConfig.position = { top: '5%' };
    this.dialog.open(SignInComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) {
          this.getUserDetails();
        }
      });
  }

  // On city
  onCitySelect() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "600px";
    dialogConfig.position = { top: '5%' };
    this.dialog.open(CitiesComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) {
          this.currentLocation = this.accessService.getLocation();
        }
      });
  }

  // On change password
  onChangePassowrd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "420px";
    dialogConfig.maxHeight = "90vh";
    dialogConfig.position = { top: '5%' };
    dialogConfig.data = {
      data: this.user
    };
    this.dialog.open(ChangePasswordFormComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) {
        }
      });
  }

  // Check for home active
  isLinkActive(url): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1 ? this.router.url : this.router.url.slice(0, queryParamsIndex);
    return baseUrl === url;
  }

  // Check session data
  checkSession() {
    // Check local data
    this.timer = setInterval(() => {
      const authInformation = this.accessService.localDataExists();
      if (!authInformation) {
        clearInterval(this.timer);
        this.accessService.logOut();
        this.snackbarService.success('Session expired!', 'Dismiss', 'bottom', 1000);
      }
    }, 3000);
  }

  // Check account status
  checkAccountStatus() {
    clearInterval(this.timer);
    this.accessService.logOut();
    this.snackbarService.success('Account deactivated!', 'Dismiss', 'bottom', 1000);
  }

  // Logout from app
  onLogout() {
    clearInterval(this.timer);
    this.accessService.logOut();
    this.snackbarService.success('Successfully logout.', 'Dismiss', 'bottom', 1000);
  }

  ngOnDestroy() {
    this.authListnerSub.unsubscribe();
    this.userSubs.unsubscribe();
    clearInterval(this.timer);
  }
}
