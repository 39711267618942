import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Password } from 'src/app/public/access/access.model';
import { environment } from 'src/environments/environment';
import { Identity } from './account.model';

const BACKEND_URL_USER = environment.apiBaseUrl + "/customers";
const BACKEND_URL_IDENTITIES = environment.apiBaseUrl + "/identities";
const BACKEND_URL_UPLOADS = environment.apiBaseUrl + "/uploads";
const BACKEND_URL_CARBONS = environment.apiBaseUrl + "/carbons";
const BACKEND_URL_WALLETS = environment.apiBaseUrl + "/wallets";
const BACKEND_URL_DEPOSIT = environment.apiBaseUrl + "/deposits";
const BACKEND_URL_VERIFICATION = environment.apiBaseUrl + "/verification";

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient, private router: Router) { }

  // Update user
  updateUser(userData: any) {
    return this.http.put<{ message: string }>(BACKEND_URL_USER + '/' + userData.id, userData);
  }

  // Update identity by customer
  updateIdentityByCustomer(identityData: Identity) {
    return this.http.put<{ message: string }>(BACKEND_URL_IDENTITIES + '/customer/' + identityData.customerId, identityData);
  }

  // Update password
  updatePassword(passwordData: Password) {
    return this.http.put<{ message: string }>(BACKEND_URL_USER + '/change-password/' + passwordData.id, passwordData);
  }

  // Upload image
  uploadImage(folder: string, id: string, file: File | string) {
    const queryParams = `?folder=${folder}&userId=${id}`;
    const formData: FormData = new FormData();
    formData.append("file", file);
    return this.http.post<{ message: string, fileUrl: string, thumbnail: string }>(BACKEND_URL_UPLOADS + '/file' + queryParams, formData);
  }

  // Get wallet details
  getWalletByCustomer(customerId: string) {
    return this.http.get<{ message: string, wallet: any }>(BACKEND_URL_WALLETS + '/customer/' + customerId);
  }

  // Add order
  addDepositOrderPaytm(orderData: any) {
    return this.http.post<{ message: string, order: any }>(BACKEND_URL_DEPOSIT + '/paytm/order', orderData);
  }

  // Add verification mobile
  addMobileVerification(verificationData: any) {
    return this.http.post<{ message: string, countryCode: string, mobile: string, hash: string }>(BACKEND_URL_VERIFICATION + '/code', verificationData);
  }

  // Add verification email
  addEmailVerification(verificationData: any) {
    return this.http.post<{ message: string }>(BACKEND_URL_VERIFICATION + '/link', verificationData);
  }

  // Verify code
  verifyCode(verificationData: any) {
    return this.http.post<{ message: string }>(BACKEND_URL_VERIFICATION + '/code/verify', verificationData);
  }
}
