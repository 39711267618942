import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AccessService } from 'src/app/public/access/access.service';
import { SnackbarService } from 'src/app/shared/notifications/snackbar.service';
import { SignInComponent } from '../sign-in/sign-in.component';

@Component({
  selector: 'app-mobile-verification',
  templateUrl: './mobile-verification.component.html',
  styleUrls: ['./mobile-verification.component.scss']
})
export class MobileVerificationComponent implements OnInit, OnDestroy {

  // Set variables
  isLoading = false;
  type: string;
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  mobile: string;
  private hash: string;
  userIsAuthenticated = false;
  private authStatusSub: Subscription;
  timeLeft: number = 120;
  interval;

  constructor(@Inject(MAT_DIALOG_DATA) private data, public accessService: AccessService, private formBuilder: FormBuilder, public snackbarService: SnackbarService, private dialogRef: MatDialogRef<MobileVerificationComponent>, public dialog: MatDialog) { }

  // Form builder
  codeVerificationForm = this.formBuilder.group({
    code: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
  });

  ngOnInit(): void {
    // Patch values
    if (this.data) {
      this.type = this.data.code.type;
      this.countryCode = this.data.code.countryCode;
      this.mobile = this.data.code.mobile;
      this.hash = this.data.code.hash;

      if (this.type === 'registration') {
        this.firstName = this.data.code.firstName;
        this.lastName = this.data.code.lastName;
        this.email = this.data.code.email;
      }
      // Set interval
      this.setResendInterval();
    }

    // Check for valid user 
    this.userIsAuthenticated = this.accessService.getIsAuth();
    this.authStatusSub = this.accessService.getAuthStatusListner().subscribe(authStatus => {
      this.isLoading = false;
      this.userIsAuthenticated = authStatus;
    });
  }

  // Form getter
  get codeVerificationControls() {
    return this.codeVerificationForm.controls;
  }

  // Send interval
  setResendInterval() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 30;
      }
      if (this.timeLeft === 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  // On form submit
  onSubmit() {
    if (this.codeVerificationForm.valid) {
      this.isLoading = true;

      if (this.type === 'login') {
        let codeVerificationDetails = {
          countryCode: this.countryCode,
          mobile: this.mobile,
          hash: this.hash,
          code: this.codeVerificationControls.code.value
        }
        this.accessService.access(codeVerificationDetails);
        this.authStatusSub = this.accessService.getAuthStatusListner().subscribe(authStatus => {
          this.isLoading = false;
          this.userIsAuthenticated = authStatus;
          if (this.userIsAuthenticated) {
            this.onClose(true);
            this.snackbarService.success('Login successfull', 'Dismiss', 'bottom', 2000);
          }
        });
      }

      if (this.type === 'registration') {
        let codeVerificationDetails = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          countryCode: this.countryCode,
          mobile: this.mobile,
          hash: this.hash,
          code: this.codeVerificationControls.code.value
        }
        this.accessService.registration(codeVerificationDetails);
        this.authStatusSub = this.accessService.getAuthStatusListner().subscribe(authStatus => {
          this.isLoading = false;
          this.userIsAuthenticated = authStatus;
          if (this.userIsAuthenticated) {
            this.onClose(true);
            this.snackbarService.success('Account created', 'Dismiss', 'bottom', 2000);
          }
        });
      }
    }
  }

  // On resend code
  onResend() {
    if (this.mobile) {
      this.isLoading = true;
      let verificationDetails = {
        countryCode: this.countryCode,
        mobile: this.mobile
      }
      this.accessService.sendOTP(verificationDetails).subscribe(resultData => {
        this.isLoading = false;
        this.countryCode = resultData.countryCode;
        this.mobile = resultData.mobile;
        this.hash = resultData.hash;
        this.setResendInterval();
        this.snackbarService.success('OTP sent', 'Dismiss', 'bottom', 3000);
      }, (error: any) => {
        this.isLoading = false;
        console.log(error);
      });
    }
  }

  // On change mobile
  onChangeMobile() {
    this.onClose(false);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "420px";
    dialogConfig.maxHeight = "90vh";
    dialogConfig.position = { top: '5%' };
    this.dialog.open(SignInComponent, dialogConfig)
      .afterClosed().subscribe(status => {
        if (status) {
        }
      });
  }

  // Number validation 
  onKeypress(event: any) {
    const pattern = /[0-9\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // Close modal
  onClose(status: boolean) {
    this.dialogRef.close(status);
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    clearInterval(this.interval);
  }

}


