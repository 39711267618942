<div class="modal">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="modal-header">
        <div class="modal-title">
            <h3>Login to your account</h3>
            <div class="div-spacer"></div>
            <button mat-icon-button class="btn-modal-close" color="primary" (click)="onClose(false)" tabIndex="-1">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="signinForm">
        <div class="modal-body modal-body-scroller">
            <div fxLayout.xs="row" fxLayout.sm="row" fxLayout.md="row" fxLayout.lg="row" fxLayout.xl="row"
                fxLayoutGap="6px" fxLayoutGap.xs="0px" fxLayoutGap.sm="0px" fxLayoutAlign="start stretch">
                <div fxFlex="14%">
                    <div class="input-label">
                        <h4>+{{countryCode}}</h4>
                    </div>
                </div>
                <div fxFlex="86%">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="fill" color="accent">
                        <mat-label>Phone number*</mat-label>
                        <input matInput type="tel" formControlName="mobile" minlength="10" maxlength="10"
                            (keypress)="onKeypress($event)" autocomplete="off" required>
                        <mat-error *ngIf="signinControls.mobile.hasError('required')">
                            Phone number is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="signinControls.mobile.hasError('minlength')">
                            Phone number must be <strong>10 digits</strong>
                        </mat-error>
                        <mat-error *ngIf="signinControls.mobile.hasError('maxlength')">
                            Phone number must be <strong>maximum 10 digits</strong>
                        </mat-error>
                        <mat-hint>OTP will be sent to this number</mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="access">
                <div class="or-section text-center">
                    <button class="full-square-flat-button" mat-flat-button type="button"
                        [disabled]="!signinControls.mobile.valid" color="primary" (click)="onSubmit()">Verify</button>
                </div>
                <br>
                <div class="green-underline-regular"></div>
                <br>
                <div fxLayout="row" fxLayoutGap="36px" fxLayoutAlign="start stretch">
                    <div fxFlex="100%" class="text-center">
                        <a (click)="onSignup('')">Create an
                            account?</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>