<!-- <div class="modal">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="modal-header">
        <div class="modal-title">
            <h3>Create an account</h3>
            <div class="div-spacer"></div>
            <button mat-icon-button class="btn-modal-close" color="primary" (click)="onClose(false)" tabIndex="-1">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="signupForm" (ngSubmit)="onSubmitPassword()">
        <div class="modal-body modal-body-scroller">
            <div fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row" fxLayout.lg="row" fxLayout.xl="row"
                fxLayoutGap="6px" fxLayoutGap.xs="0px" fxLayoutGap.sm="0px" fxLayoutAlign="start stretch">
                <div fxFlex="50%">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="fill" color="accent">
                        <mat-label>First name*</mat-label>
                        <input matInput type="text" formControlName="firstName" autocomplete="off" required>
                        <mat-error *ngIf="signupControls.firstName.hasError('required')">
                            First name is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="50%">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="fill" color="accent">
                        <mat-label>Last name*</mat-label>
                        <input matInput type="text" formControlName="lastName" autocomplete="off" required>
                        <mat-error *ngIf="signupControls.lastName.hasError('required')">
                            Last name is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row" fxLayout.lg="row" fxLayout.xl="row"
                fxLayoutGap="6px" fxLayoutAlign="start stretch">
                <div fxFlex="100%">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="fill" color="accent">
                        <mat-label>Email address*</mat-label>
                        <input matInput type="text" formControlName="email" autocomplete="off" required>
                        <mat-error *ngIf="signupControls.email.hasError('required')">
                            Email address is <strong>required</strong>
                        </mat-error>
                        <mat-error
                            *ngIf="signupControls.email.hasError('email') && !signupControls.email.hasError('required')">
                            Please enter a valid email address
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout.xs="row" fxLayout.sm="row" fxLayout.md="row" fxLayout.lg="row" fxLayout.xl="row"
                fxLayoutGap="6px" fxLayoutGap.xs="0px" fxLayoutGap.sm="0px" fxLayoutAlign="start stretch">
                <div fxFlex="14%">
                    <div class="input-label">
                        <h4>+{{countryCode}}</h4>
                    </div>
                </div>
                <div fxFlex="86%">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="fill" color="accent">
                        <mat-label>Phone number*</mat-label>
                        <input matInput type="text" formControlName="mobile" minlength="10" maxlength="10"
                            (keypress)="onKeypress($event)" autocomplete="off" required>
                        <mat-error *ngIf="signupControls.mobile.hasError('required')">
                            Phone number is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="signupControls.mobile.hasError('minlength')">
                            Phone number must be <strong>10 digits</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row" fxLayout.lg="row" fxLayout.xl="row"
                fxLayoutGap="6px" fxLayoutAlign="start stretch">
                <div fxFlex="100%">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="fill" color="accent">
                        <mat-label>Password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required>
                        <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="signupControls.password.hasError('required')">
                            Password is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="access">
                <div class="text-center">
                    <button class="full-square-flat-button" mat-flat-button type="submit" [disabled]="!signupForm.valid"
                        color="accent">Create</button>
                    <p>By creating an account, I accept the <a href="/terms-and-conditions" target="_blank">Terms &
                            Conditions</a>
                    </p>
                </div>
                <br>
                <div class="green-underline-regular"></div>
                <br>
                <div class="text-center">
                    <a (click)="onSignin()">Back to login?</a>
                </div>
            </div>
        </div>
    </form>
</div> -->


<div class="modal">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="modal-header">
        <div class="modal-title">
            <h3>Create an account</h3>
            <div class="div-spacer"></div>
            <button mat-icon-button class="btn-modal-close" color="primary" (click)="onClose(false)" tabIndex="-1">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
        <div class="modal-body modal-body-scroller">
            <div fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row" fxLayout.lg="row" fxLayout.xl="row"
                fxLayoutGap="6px" fxLayoutGap.xs="0px" fxLayoutGap.sm="0px" fxLayoutAlign="start stretch">
                <div fxFlex="50%">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="fill" color="accent">
                        <mat-label>First name*</mat-label>
                        <input matInput type="text" formControlName="firstName" autocomplete="off" required>
                        <mat-error *ngIf="signupControls.firstName.hasError('required')">
                            First name is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="50%">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="fill" color="accent">
                        <mat-label>Last name*</mat-label>
                        <input matInput type="text" formControlName="lastName" autocomplete="off" required>
                        <mat-error *ngIf="signupControls.lastName.hasError('required')">
                            Last name is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row" fxLayout.lg="row" fxLayout.xl="row"
                fxLayoutGap="6px" fxLayoutAlign="start stretch">
                <div fxFlex="100%">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="fill" color="accent">
                        <mat-label>Email address*</mat-label>
                        <input matInput type="text" formControlName="email" autocomplete="off" required>
                        <mat-error *ngIf="signupControls.email.hasError('required')">
                            Email address is <strong>required</strong>
                        </mat-error>
                        <mat-error
                            *ngIf="signupControls.email.hasError('email') && !signupControls.email.hasError('required')">
                            Please enter a valid email address
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout.xs="row" fxLayout.sm="row" fxLayout.md="row" fxLayout.lg="row" fxLayout.xl="row"
                fxLayoutGap="6px" fxLayoutGap.xs="0px" fxLayoutGap.sm="0px" fxLayoutAlign="start stretch">
                <div fxFlex="14%">
                    <div class="input-label">
                        <h4>+{{countryCode}}</h4>
                    </div>
                </div>
                <div fxFlex="86%">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="fill" color="accent">
                        <mat-label>Phone number*</mat-label>
                        <input matInput type="tel" formControlName="mobile" minlength="10" maxlength="10"
                            (keypress)="onKeypress($event)" autocomplete="off" required>
                        <mat-error *ngIf="signupControls.mobile.hasError('required')">
                            Phone number is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="signupControls.mobile.hasError('minlength')">
                            Phone number must be <strong>10 digits</strong>
                        </mat-error>
                        <mat-error *ngIf="signupControls.mobile.hasError('maxlength')">
                            Phone number must be <strong>maximum 10 digits</strong>
                        </mat-error>
                        <mat-hint>OTP will be sent to this number</mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="access">
                <div class="text-center">
                    <button class="full-square-flat-button" mat-flat-button type="submit" [disabled]="!signupForm.valid"
                        color="accent">Verify</button>
                    <p>By creating an account, I accept the <a href="doc/user/terms-and-conditions"
                            target="_blank">Terms & Conditions</a>
                    </p>
                </div>
                <br>
                <div class="green-underline-regular"></div>
                <br>
                <div class="text-center">
                    <a (click)="onSignin()">Back to login?</a>
                </div>
            </div>
        </div>
    </form>
</div>