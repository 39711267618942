<div class="modal">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="modal-header">
        <div class="modal-title">
            <h3>Code Verification</h3>
            <div class="div-spacer"></div>
            <button mat-icon-button class="btn-modal-close" color="primary" (click)="onClose(false)" tabIndex="-1">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="resetpasswordForm" (ngSubmit)="onSubmit()">
        <div class="modal-body">
            <div fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="row" fxLayout.lg="row" fxLayout.xl="row"
                fxLayoutGap="16px" fxLayoutAlign="start stretch">
                <div fxFlex="100%">
                    <div class="message">
                        <h5>We've sent you a email on <b>{{email}}</b>. Please enter the code below. <a
                                (click)="onChangeEmail()">Change
                                email</a></h5>
                    </div>
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline" color="accent">
                        <mat-label>4-Digit code</mat-label>
                        <input matInput type="text" formControlName="code" minlength="4" maxlength="4"
                            (keypress)="onKeypress($event)" autocomplete="off" required>
                        <mat-error *ngIf="resetpasswordControls.code.hasError('required')">
                            Verification code is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="resetpasswordControls.code.hasError('minlength')">
                            Verification code must be <strong>4 digits</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline" color="accent">
                        <mat-label>New password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required>
                        <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="resetpasswordControls.password.hasError('required')">
                            Password is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="outline" color="accent">
                        <mat-label>Confirm password</mat-label>
                        <input matInput type="password" formControlName="confirmPassword" required>
                        <mat-error *ngIf="resetpasswordControls.confirmPassword.hasError('required')">
                            Confirm password is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="resetpasswordControls.confirmPassword.hasError('missMatch')">
                            Password not <strong>match!</strong>
                        </mat-error>
                    </mat-form-field>

                    <div class="resendLink">
                        <h4 (click)="onResend()">Resend code via email ></h4>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button class="full-square-flat-button" mat-flat-button type="submit" [disabled]="!resetpasswordForm.valid"
                color="accent">Change Password</button>
        </div>
    </form>
</div>