<div class="modal">
    <div class="modal-body text-center">
        <div class="modal-message">
            <h3>Update!</h3>
            <h5>App updates are available! Please update you current app with new updated features.</h5>
            <br><br>
            <small><i>This action will reload you current app!</i></small>
        </div>
    </div>
    <div class="modal-footer text-center">
        <button mat-flat-button (click)="onUpdate()" class="full-square-flat-button" color="accent">Update</button>
    </div>
</div>