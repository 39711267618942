import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccessService } from 'src/app/public/access/access.service';
import { SearchService } from 'src/app/public/search/search.service';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent implements OnInit {

  // Set variables
  isLoading = false;
  cities: any;
  currentLocation: any;
  show: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) private data, public searchService: SearchService, public accessService: AccessService, private router: Router, private dialogRef: MatDialogRef<CitiesComponent>) { }

  ngOnInit(): void {
    // Get all cities
    this.getCities();

    // Get current location
    this.currentLocation = this.accessService.getLocation();
    if (!this.currentLocation) {
      this.show = false;
    }
  }

  // Get cities
  getCities() {
    this.isLoading = true;
    this.searchService.getCitiesList(0, 0).subscribe(resultData => {
      let citiesArray = resultData.cities.map(item => {
        return {
          id: item._id,
          name: item.name,
          description: item.description,
          bookingHours: item.bookingHours,
          image: item.image,
          icon: item.icon,
          isActive: item.isActive
        }
      });
      this.cities = citiesArray;
      this.isLoading = false;
    }, (error: any) => {
      this.isLoading = false;
      console.log(error);
    });
  }

  // Select city
  selectCity(city: any) {
    const location = {
      id: city.id,
      name: city.name,
      description: city.description,
      bookingHours: city.bookingHours,
      image: city.image,
      isActive: city.isActive
    }
    localStorage.setItem("lytport_customer_location", JSON.stringify(location));
    this.onClose(true);
    this.router.navigate(['/' + location.name.toLowerCase() + '/rentals']);
  }

  // Close modal
  onClose(status: boolean) {
    this.dialogRef.close(status);
  }
}