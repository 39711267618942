<mat-progress-bar mode="indeterminate" *ngIf="isLoading" color="primary"></mat-progress-bar>
<div class="verification">
    <div class="logo">
        <a [routerLink]="['/']">
            <img class="text" src="assets/images/logo-text.svg" alt="Selfspin logo">
        </a>
    </div>
    <div class="log-div" *ngIf="verification === 200">
        <mat-icon class="material-icons-outlined verified-icon">done</mat-icon>
        <h4>Email address verified</h4>
    </div>
    <div class="log-div" *ngIf="verification === 401">
        <mat-icon class="material-icons-outlined error-icon">error</mat-icon>
        <h4>Invalid verification request!</h4>
    </div>
    <div class="log-div" *ngIf="verification === 402">
        <mat-icon class="material-icons-outlined verified-icon">done</mat-icon>
        <h4>Email address already verified!</h4>
    </div>
    <div class="log-div" *ngIf="verification === 403">
        <mat-icon class="material-icons-outlined pending-icon">error</mat-icon>
        <h4>Verification link expired! Please re-verify your email address.</h4>
    </div>
</div>