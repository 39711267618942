import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) { }

  // Configure snackbar 
  config: MatSnackBarConfig = {}

  // Success snackbar
  success(message: string, action: string, position: any, duration: number) {
    this.config['panelClass'] = ['success-snackbar'];
    this.config.verticalPosition = position;
    this.config.horizontalPosition = 'center';
    this.config.duration = duration;
    this.snackBar.open(message, action, this.config);
  }

  // Error snackbar
  error(message: string, action: string, position: any, duration: number) {
    this.config['panelClass'] = ['error-snackbar'];
    this.config.verticalPosition = position;
    this.config.horizontalPosition = 'center';
    this.config.duration = duration;
    this.snackBar.open(message, action, this.config);
  }
}
