import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Cities, Coupons, Metadata, Stations, Vehicles } from './search.model';

const BACKEND_URL_CITIES = environment.apiBaseUrl + "/cities";
const BACKEND_URL_STATIONS = environment.apiBaseUrl + "/stations";
const BACKEND_URL_VEHICLES = environment.apiBaseUrl + "/vehicles";
const BACKEND_URL_BOOKINGS = environment.apiBaseUrl + "/bookings";
const BACKEND_URL_MODELS = environment.apiBaseUrl + "/models";
const BACKEND_URL_COUPONS = environment.apiBaseUrl + "/coupons";
const BACKEND_URL_METADATA = environment.apiBaseUrl + "/metadata";
const HEADERS = {
  headers: new HttpHeaders({
    'Authorization': 'Bearer ' + environment.webToken,
    'Username': environment.webUser
  })
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  // Set variables
  private cities: Cities[] = [];
  private citiesUpdated = new Subject<{ cities: Cities[], citiesCount: number }>();

  private stations: Stations[] = [];
  private stationsUpdated = new Subject<{ stations: Stations[], stationsCount: number }>();

  private vehicles: Vehicles[] = [];
  private vehiclesUpdated = new Subject<{ vehicles: Vehicles[], vehiclesCount: number }>();

  private coupons: Coupons[] = [];
  private couponsUpdated = new Subject<{ coupons: Coupons[], couponsCount: number }>();

  private metaData: Metadata[] = [];
  private metaDataUpdated = new Subject<{ metaData: Metadata[], metaDataCount: number }>();

  constructor(private http: HttpClient, private router: Router) { }

  // Get all cities
  getCities(dataPerPage: number, currentPage: number) {
    const queryParams = `?pagesize=${dataPerPage}&page=${currentPage}`;
    this.http.get<{ message: string, cities: any, maxCities: number }>(BACKEND_URL_CITIES + queryParams, HEADERS)
      .pipe(map((citiesData) => {
        return {
          cities: citiesData.cities.map((city) => {
            return {
              id: city._id,
              name: city.name,
              description: city.description,
              image: city.image,
              icon: city.icon,
              latitude: city.latitude,
              longitude: city.longitude,
              geofenceShape: city.geofenceShape,
              radius: city.radius,
              area: city.area,
              vehiclesCount: city.vehiclesCount,
              isActive: city.isActive
            }
          }),
          maxCities: citiesData.maxCities
        };
      }))
      .subscribe((transformedCitiesData) => {
        this.cities = transformedCitiesData.cities;
        this.citiesUpdated.next({
          cities: [...this.cities],
          citiesCount: transformedCitiesData.maxCities
        });
      });
  }

  // Listen cities update
  getCitiesUpdatedListener() {
    return this.citiesUpdated.asObservable();
  }

  // Get cities list
  getCitiesList(dataPerPage: number, currentPage: number) {
    const queryParams = `?pagesize=${dataPerPage}&page=${currentPage}`;
    return this.http.get<{ message: string, cities: any, maxCities: number }>(BACKEND_URL_CITIES + queryParams, HEADERS);
  }

  // Get all stations by city
  getStationsByCity(dataPerPage: number, currentPage: number, cityId: string) {
    const queryParams = `?pagesize=${dataPerPage}&page=${currentPage}`;
    this.http.get<{ message: string, stations: any, maxStations: number }>(BACKEND_URL_STATIONS + '/city/' + cityId + queryParams, HEADERS)
      .pipe(map((stationsData) => {
        return {
          stations: stationsData.stations.map((station) => {
            return {
              id: station._id,
              name: station.name,
              address: station.address,
              zipcode: station.zipcode,
              latitude: station.latitude,
              longitude: station.longitude,
              geofenceShape: station.geofenceShape,
              radius: station.radius,
              area: station.area,
              isActive: station.isActive
            }
          }),
          maxStations: stationsData.maxStations
        };
      }))
      .subscribe((transformedStationsData) => {
        this.stations = transformedStationsData.stations;
        this.stationsUpdated.next({
          stations: [...this.stations],
          stationsCount: transformedStationsData.maxStations
        });
      });
  }

  // Listen stations update
  getStationsUpdatedListener() {
    return this.stationsUpdated.asObservable();
  }

  // Get models list
  getModelsList(dataPerPage: number, currentPage: number) {
    const queryParams = `?pagesize=${dataPerPage}&page=${currentPage}`;
    return this.http.get<{ message: string, models: any, maxModels: number }>(BACKEND_URL_MODELS + '/brands/group' + queryParams, HEADERS);
  }

  // Get all vehicles with fares
  getVehiclesWithFares(dataPerPage: number, currentPage: number, filterData: any) {
    var parseModels = JSON.stringify(filterData.models);
    const queryParams = `?pagesize=${dataPerPage}&page=${currentPage}&pricingType=${filterData.pricingType}&city=${filterData.city}&pickupStation=${filterData.pickupStation}&dropStation=${filterData.dropStation}&pickupDateTime=${filterData.pickupDateTime}&dropDateTime=${filterData.dropDateTime}&partnerId=000000&transmission=${filterData.transmission}&costRange=${filterData.costRange}&engineCapacity=${filterData.engineCapacity}&engineCapacityAbove=${filterData.engineCapacityAbove}&models=${parseModels}`;
    this.http.get<{ message: string, vehicles: any, maxVehicles: number }>(BACKEND_URL_VEHICLES + '/filter/fares' + queryParams, HEADERS)
      .pipe(map((vehiclesData) => {
        return {
          vehicles: vehiclesData.vehicles.map((vehicle) => {
            return {
              id: vehicle._id,
              brand: vehicle.brand,
              model: vehicle.model,
              IOTNumber: vehicle.IOTNumber,
              vehicleTrackingId: vehicle.vehicleTrackingId,
              QRCode: vehicle.QRCode,
              QRCodeString: vehicle.QRCodeString,
              image: vehicle.image,
              thumbnail: vehicle.thumbnail,
              vehicleNumber: vehicle.vehicleNumber,
              chassisNumber: vehicle.chassisNumber,
              vehicleStatus: vehicle.vehicleStatus,
              city: vehicle.city,
              pricingType: vehicle.pricingType,
              station: vehicle.station,
              weekdayPrice: vehicle.weekdayPrice,
              weekdayExcessPrice: vehicle.weekdayExcessPrice,
              weekdayExtendPrice: vehicle.weekdayExtendPrice,
              weekendPrice: vehicle.weekendPrice,
              weekendExcessPrice: vehicle.weekendExcessPrice,
              weekendExtendPrice: vehicle.weekendExtendPrice,
              baseExtendPrice: vehicle.baseExtendPrice,
              extraHelmetPrice: vehicle.extraHelmetPrice,
              xFactorPrice: vehicle.xFactorPrice,
              perMinutePrice: vehicle.perMinutePrice,
              perKilometerPrice: vehicle.perKilometerPrice,
              location: vehicle.location,
              estimateTime: vehicle.estimateTime,
              estimateTimeCharges: vehicle.estimateTimeCharges,
              estimatedDistanceCharges: vehicle.estimatedDistanceCharges,
              pickupDateTime: vehicle.pickupDateTime,
              dropDateTime: vehicle.dropDateTime,
              weekdayCharges: vehicle.weekdayCharges,
              weekendCharges: vehicle.weekendCharges,
              duration: vehicle.duration,
              perHourCharges: vehicle.perHourCharges,
              listCharges: vehicle.listCharges,
              discount: vehicle.discount,
              discountApplied: vehicle.discountApplied,
              bookingCharges: vehicle.bookingCharges,
              totalCharges: vehicle.totalCharges,
              securityDeposit: vehicle.securityDeposit,
              freeKilometers: vehicle.freeKilometers,
              availabilityStatus: vehicle.availabilityStatus,
              notAvailable: vehicle.notAvailable,
              soldOut: vehicle.soldOut,
              unavailable: vehicle.unavailable,
              available: vehicle.available,
              dateType: vehicle.dateType,
              isOpen: vehicle.isOpen,
              openTime: vehicle.openTime,
              closeTime: vehicle.closeTime,
              availableFrom: vehicle.availableFrom,
              availableTill: vehicle.availableTill,
              count: vehicle.count,
              excessKilometersPrice: vehicle.excessKilometersPrice,
              isActive: vehicle.isActive
            }
          }),
          maxVehicles: vehiclesData.maxVehicles
        };
      }))
      .subscribe((transformedVehiclesData) => {
        this.vehicles = transformedVehiclesData.vehicles;
        this.vehiclesUpdated.next({
          vehicles: [...this.vehicles],
          vehiclesCount: transformedVehiclesData.maxVehicles
        });
      }, (error: any) => {
        this.vehiclesUpdated.next({
          vehicles: [],
          vehiclesCount: 0
        });
      });
  }

  // Listen vehicles with fare update
  getVehiclesWithFaresUpdatedListener() {
    return this.vehiclesUpdated.asObservable();
  }

  // Get all vehicle with fares
  getVehicleDetailsWithFares(filterData: any) {
    const queryParams = `?city=${filterData.city}&pickupStation=${filterData.pickupStation}&dropStation=${filterData.dropStation}&pickupDateTime=${filterData.pickupDateTime}&dropDateTime=${filterData.dropDateTime}`;
    return this.http.get<{ message: string, vehicle: any }>(BACKEND_URL_VEHICLES + '/filter/fares/vehicle/' + filterData.vehicleId + queryParams, HEADERS);
  }

  // Get coupon details
  getCoupons(dataPerPage: number, currentPage: number) {
    const queryParams = `?pagesize=${dataPerPage}&page=${currentPage}`;
    this.http.get<{ message: string, coupons: any, maxCoupons: number }>(BACKEND_URL_COUPONS + queryParams, HEADERS)
      .pipe(map((couponsData) => {
        return {
          coupons: couponsData.coupons.map((coupon) => {
            return {
              id: coupon._id,
              name: coupon.name,
              headline: coupon.headline,
              description: coupon.description,
              couponCode: coupon.couponCode,
              startDate: coupon.startDate,
              expiryDate: coupon.expiryDate,
              discount: coupon.discount,
              isPercentage: coupon.isPercentage,
              isActive: coupon.isActive
            }
          }),
          maxCoupons: couponsData.maxCoupons
        };
      }))
      .subscribe((transformedCouponsData) => {
        this.cities = transformedCouponsData.coupons;
        this.couponsUpdated.next({
          coupons: [...this.coupons],
          couponsCount: transformedCouponsData.maxCoupons
        });
      });
  }

  // Listen coupons update
  getCouponsUpdatedListener() {
    return this.couponsUpdated.asObservable();
  }

  // Get coupon list
  getCouponsList(dataPerPage: number, currentPage: number) {
    const queryParams = `?pagesize=${dataPerPage}&page=${currentPage}`;
    return this.http.get<{ message: string, coupons: any, maxCoupons: number }>(BACKEND_URL_COUPONS + queryParams, HEADERS);
  }

  // Get meta data details
  getMetaData(dataPerPage: number, currentPage: number) {
    const queryParams = `?pagesize=${dataPerPage}&page=${currentPage}`;
    this.http.get<{ message: string, metaData: any, maxMetaData: number }>(BACKEND_URL_METADATA + queryParams, HEADERS)
      .pipe(map((data) => {
        return {
          metaData: data.metaData.map((item) => {
            return {
              id: item._id,
              city: item.city._id,
              name: item.name,
              description: item.description,
              metaTitle: item.metaTitle,
              metaDescription: item.metaDescription,
              metaKeywords: item.metaKeywords,
              isActive: item.isActive
            }
          }),
          maxMetaData: data.maxMetaData
        };
      }))
      .subscribe((transformedMetaData) => {
        this.metaData = transformedMetaData.metaData;
        this.metaDataUpdated.next({
          metaData: [...this.metaData],
          metaDataCount: transformedMetaData.maxMetaData
        });
      });
  }

  // Listen metadata update
  getMetaDataUpdatedListener() {
    return this.metaDataUpdated.asObservable();
  }

  // Get metadata list
  getMetaDataList(dataPerPage: number, currentPage: number) {
    const queryParams = `?pagesize=${dataPerPage}&page=${currentPage}`;
    return this.http.get<{ message: string, metadata: any, maxMetaData: number }>(BACKEND_URL_METADATA + queryParams, HEADERS);
  }

  // Get Meta Data by City
  getMetaDataByCity(dataPerPage: number, currentPage: number, cityId: string) {
    const queryParams = `?pagesize=${dataPerPage}&page=${currentPage}`;
    return this.http.get<{ message: string, metadata: any, maxMetaData: number }>(BACKEND_URL_METADATA + '/city/' + cityId + queryParams, HEADERS);
  }
}
