import { Component, OnInit } from '@angular/core';
import { AccessService } from '../public/access/access.service';
import { SnackbarService } from '../shared/notifications/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {

  // Set variables
  isLoading = false;
  verification: number = 400;
  token: string;

  constructor(public accessService: AccessService, private activatedRoute: ActivatedRoute, private router: Router, public snackbarService: SnackbarService) { }

  ngOnInit(): void {
    // Get token
    this.activatedRoute.params.subscribe((params) => {
      this.token = params['token'];
      this.onSubmit(this.token);
    });
  }

  // On form submit
  onSubmit(token: string) {
    if (token) {
      this.isLoading = true;
      let verificationDetails = {
        token: token
      }
      this.accessService.verifyEmailUser(verificationDetails).subscribe(resultData => {
        this.isLoading = false;
        this.verification = resultData.status;
        if (this.verification === 200) {
          this.snackbarService.success('Email address verified!', 'Dismiss', 'bottom', 4000);
        }
      }, (error: any) => {
        this.isLoading = false;
        console.log(error);
      });
    }
  }
}